<template>
    <modal ref="modalEditarDuracion" titulo="Editar duración" tamano="modal-md" adicional="Aceptar" @adicional="editarDuracion">
        <div class="mx-3">
            <ValidationObserver ref="validator">
                <ValidationProvider v-slot="{ errors }" name="duracion" rules="required">
                    <p class="input-label-top">Duración</p>
                    <el-date-picker
                        v-model="fecha"
                        type="daterange"
                        placeholder="Seleccionar un día"
                        value-format="yyyy-MM-dd"
                        size="small"
                        class="w-100"
                    />
                    <vee-error :text="errors[0]"></vee-error>
                </ValidationProvider>

                <div class="d-flex align-items-end">
                    <div class="mr-4">
                        <p class="input-label-top mt-3">Días</p>
                        <el-input-number v-model="numDias" :controls="false" :min="1" size="small" />
                    </div>
                    <button class="btn btn-general f-13 px-2" @click="recalcularFechaFin">Recalcular fecha fin</button>
                </div>

                <ValidationProvider v-slot="{ errors }" name="justificacion" rules="required">
                    <el-input
                        v-model="justificacion"
                        :rows="3"
                        type="textarea"
                        maxlength="400"
                        show-word-limit
                        placeholder="Por favor, escriba una justificación no mayor a 400 caracteres"
                        class="w-100 mt-3"
                    />
                    <vee-error :text="errors[0]"></vee-error>
                </ValidationProvider>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
export default {
    data(){
        return {
            fecha: null,
            numDias: '',
            justificacion: '',
        }
    },
    computed:{
        ...mapGetters({
            token: 'auth/token'
        })
    },
    methods: {
        ...mapActions({
            Action_post_edit_duration_FS: 'obras/informacion/Action_post_edit_duration_FS'
        }),
        toggle(){
            this.$refs.validator.reset()
            this.$refs.modalEditarDuracion.toggle()
        },
        recalcularFechaFin(){
            if (this.fecha && this.numDias) {
                const fechaIni = this.fecha[0]
                const fechaFin = moment(fechaIni)
                fechaFin.add(this.numDias, 'd')
                this.fecha = [fechaIni, fechaFin.format('YYYY-MM-DD')]
            }
        },
        async editarDuracion(){
            const validado = await this.$refs.validator.validate()
            if (!validado) return
            const payload = {
                fecha_inicio: this.fecha[0],
                fecha_fin: this.fecha[1],
                id_proyecto: this.$route.params.id,
                justificacion: this.justificacion,
                token: this.token
            }
            this.$refs.modalEditarDuracion.toggle()
            await this.Action_post_edit_duration_FS(payload)
            await Promise.all([
                this.Action_get_informacion_header_FS({id: this.$route.params.id, token: this.token}),
                this.Action_get_history_header_FS({id: this.$route.params.id, token: this.token})
            ])
        }
    }
}
</script>