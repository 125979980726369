<template>
    <section class="px-3">
        <navbar-admin titulo="Obras" />
        <div class="d-flex flex-column mx-0 bg-whitesmoke p-3 br-4" style="border: #DBDBDB 1px solid;">
            <!-- FECHAS FINALES Y GRUPOS -->
            <div v-if="informacionFS.calendario" class="d-middle justify-content-end gap-2" style="cursor: default;">
                <div class="br-4 py-2 px-4 bg-general text-white">Inicio</div>
                <div class="border-general br-4 p-2 px-3">
                    <p class="text-muted f-14">{{ informacionFS.calendario.inicio ? informacionFS.calendario.inicio : informacionFS.calendario.historial.fecha_inicio }}</p>
                </div>
                <div class="br-4 py-2 px-4 bg-general text-white">Final</div>
                <div class="border-general br-4 p-2 px-3">
                    <p class="text-muted f-14">{{ informacionFS.calendario.fin ? informacionFS.calendario.fin : informacionFS.calendario.historial.fecha_fin }}</p>
                </div>
            </div>
            <time-line-header />

            <div v-if="informacionFS.proyecto" class="position-relative w-100 mb-1">
                <img style="width:100%; height: 150px; background-color: rgba(0,0,0,.15);"
                    class="img-banner obj-cover d-block br-5" :src="informacionFS.proyecto.cliente.banner_1 | helper-storage-sandbox" alt="banner"
                />
                <img height="92" width="92"
                    class="position-absolute rounded-circle obj-cover bg-white estilo-img"
                    :src="informacionFS.proyecto.cliente.logo | helper-storage-sandbox"
                    alt="logo"
                />
                <div class="bg-semi-transparent f-18 position-absolute px-2 br-4 text-white d-middle" style="top:6px;left:9px">
                    <i class="icon-alert mr-1" />
                    <p class="f-16 f-600 pl-1">{{ informacionFS.proyecto.aspectos_criticos || 0 }} Aspectos críticos</p>
                </div>
                <div style="top:5px;right:5px;" class="position-absolute">
                    <progress-ring class="mx-3" size="small" :percentage="69" wh="60" bg="#525252dd" />
                </div>
                <div class="bg-semi-transparent f-18 position-absolute px-2 br-4 text-white d-middle" style="bottom:7px;left:117px; max-width: 500px;">
                    <i class="icon-rocket f-15" />
                    <p class="pl-1 f-600 tres-puntos">
                        {{ informacionFS.proyecto.nombre || 'No encontrado' }}
                    </p>
                </div>
                <div class="bg-semi-transparent position-absolute px-2 br-4 text-white d-middle" style="bottom:7px;right:7px;">
                    <div >
                        <i v-if="informacionFS && informacionFS.calendario && informacionFS.calendario.historial">
                            <i class="icon-calendar-today f-18" />
                            {{ informacionFS.calendario.historial.fecha_inicio || '' | helper-fecha('DD MMM YYYY')}} - 
                            {{ informacionFS.calendario.historial.fecha_fin || '' | helper-fecha('DD MMM YYYY')}}
                        </i>
                    </div>
                    <i class="icon-pencil mx-2 f-23 cr-pointer" @click="editarDuracion" />
                    <i class="icon-history mr-2 f-23 cr-pointer" @click="historialDuracion" />
                </div>
            </div>
            
            <div v-if="informacionFS && informacionFS.proyecto" class="row position-relative offset-1 mt-3">
                <div class="col-3">
                    <div class="d-middle">
                        <i class="icon-factory f-20 mr-1" />
                        <i class="my-auto">{{ informacionFS.proyecto.cliente.nombre_corto }}</i>
                    </div>
                    <div class="d-middle">
                        <i class="icon-account f-20 mr-1" />
                        <i class="my-auto">{{ informacionFS.proyecto.responsable }}</i>
                    </div>
                </div>
                <div class="col-3">
                    <div class="d-middle">
                        <i class="icon-format-list-bulleted-type f-20 mr-1" />
                        <i class="my-auto">Tipo: {{ informacionFS.proyecto.tipo.nombre }}</i>
                    </div>
                    <div class="d-middle">
                        <i class="icon-clipboard-text f-20 mr-1" />
                        <i class="my-auto">{{ informacionFS.proyecto.ocs }} Ordenes de compra</i>
                    </div>
                </div>
                <div class="col-4">
                    <div class="d-middle">
                        <i class="icon-dolar f-20 mr-1" />
                        <i class="my-auto">{{ informacionFS.proyecto.valor || 0 | currency(_, 2, { thousandsSeparator: '.'}) }} COP</i>
                    </div>
                    <div v-if="informacionFS.proyecto.oportunidad" class="d-middle">
                        <i class="icon-crown f-20 mr-1" />
                        <i class="my-auto">OP {{  informacionFS.proyecto.id_oportunidad }} - {{ informacionFS.proyecto.oportunidad }}</i>
                    </div>
                </div>
            </div>
            <div class="f-14 f-500 my-1 d-middle justify-content-end">
                <p class="mr-1">Numero de identificación:</p>
                <span v-if="informacion" class="px-3 py-1 mx-0 border br-4 d-middle-center f-12">{{ informacion.numero }}</span>
                <el-popover v-model="visibleCrearNum" placement="bottom" width="230" trigger="click" class="px-0">
                    <div class="row mx-0 justify-content-center f-600 text-general font-weight-bold">
                        Editar identificación
                    </div>
                    <hr class="my-2 link-general"/>
                    <ValidationObserver ref="validatorNumber">
                    <div class="row mx-0 justify-content-center my-2">
                        <div class="col-10 my-2">
                        <ValidationProvider v-slot="{ errors }" name="numero" rules="required">
                            <p class="input-label-top">Número de identificación</p>
                            <el-input v-model="identificacion" class="f-12 br-5 w-100 col-md-20" size="small" />
                            <span class="text-danger f-11"> {{errors[0]}} </span>
                        </ValidationProvider>
                        </div>
                    </div>
                    </ValidationObserver>
                    <div class="text-center m-0">
                        <button class="btn btn-crear f-12 ml-0 col-md-8" @click="createNumber">Guardar</button>
                    </div>
                    <el-tooltip slot="reference" content="Editar número de identificación" placement="bottom" effect="light" visible-arrow>
                        <div class="icon-option">
                            <i class="icon-pencil f-20 cr-pointer" />
                        </div>
                    </el-tooltip>
                </el-popover>
                <el-popover v-model="visibleHistorial" placement="bottom" width="350" trigger="click" class="px-0">
                    <div class="row mx-0 justify-content-center f-150 text-general font-weight-bold my-0">
                        Historial de cambios
                    </div>
                    <hr class="my-1"/>
                    <div class="row mx-0">
                        <div class="col-7"></div>
                        <div class="col-5">
                            <p class="text-center font-weight-bold f-11">Cambio realizado</p>
                        </div>
                    </div>
                    <div v-if="informacion && informacion.historial" class="listado-historial custom-scroll overflow-auto" style="height: 200px;">
                        <div v-for="(historial, idx) in informacion.historial" :key="idx" class="row my-3 mx-0">
                            <div class="col-7 my-auto">
                                <card-mini-user class="f-2" :img="historial.foto" tamanoImg="24" :nombre="historial.creado_por"/>
                            </div>
                            <p class="col-5 my-auto f-12 text-center">{{historial.numero}}</p>
                        </div>
                    </div>
                    <hr />
                    <el-tooltip slot="reference" content="Historial de cambios" placement="bottom" effect="light" visible-arrow>
                        <i class="icon-history f-20 cr-pointer icon-general-hover" />
                    </el-tooltip>
                </el-popover>
            </div>
        </div>
		<tabs-iconos :tabs="tabsServiciosVer()" />
        <router-view />
        <!-- Partials -->
        <modal-eliminar ref="abrirModalEliminarServicio" title="Eliminar servicio" mensaje="¿Está seguro que quiere eliminar este servicio?" />
        <modal-agregar-servicio ref="abrirAgregarServicio" />
        <modal-editar-duracion ref="modalEditarDuracion" />
        <modal-historial-cambio-duracion ref="modalHistorialCambioDuracion" />
        <modal-finalizar-servicio ref="abrirFinalizarServicio" />
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalEditarDuracion from './partials/modalEditarDuracion'
import ModalHistorialCambioDuracion from './partials/modalHistorialCambioDuracion'
import timeLineHeader from '~/pages/obras/ver/components/timeLineHeader'
export default {
    components:{
        timeLineHeader,
        ModalEditarDuracion,
        ModalHistorialCambioDuracion,
        modalAgregarServicio:() => import('../partials/modalAgregarServicio.vue'),
        modalFinalizarServicio:() => import('../partials/modalFinalizarServicio.vue')
    },
    data(){
        return{
			FS: process.env.VUE_APP_URL_FULL_SMART,
            numeroIdentificacion: '',
            idProyecto : this.$route.params.id,
            visibleCrearNum: false,
            visibleHistorial: false,
            identificacion: ''
        }
    },
    computed:{
        ...mapGetters({
			token: 'auth/token',
            informacionFS: 'obras/informacion/informacionFS',
            informacion: 'obras/informacion/informacion'
        }),
        id(){
            return this.$route.params?.id
        }
    },
    async created(){
        await this.getInfoHeader()
        this.spliceBreadcumbs([
            {
                position: 0,
                breadcumb: {
                    name: 'Obras',
                    fs: `${this.FS}/proyectos/proceso/mosaico`,
                }
            },
            {
                position: 1,
                breadcumb: {
                    name: this.informacion?.nombre ?? '',
                    route: 'obras.vigentes.ver.equipos',
                    params: { id: this.informacion?.id ?? -1 }
                }
            }
        ])
        this.replaceBreadcumb({ position: 2, breadcumb: this.$route.name })
    },
    watch: {
        "$route.name"(val){
            this.replaceBreadcumb({
                position: 2,
                breadcumb: val
            })
        }
    },
    methods:{
        ...mapActions({
            Action_get_history_header_FS: 'obras/informacion/Action_get_history_header_FS',
            Action_get_informacion_header: 'obras/informacion/Action_get_informacion_header',
            Action_get_informacion_header_FS: 'obras/informacion/Action_get_informacion_header_FS',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_post_numero_identificacion: 'obras/numeroIdentificacion/Action_post_numero_identificacion',
        }),
        async getInfoHeader(){
            console.log(this.$route, this.id);
            await Promise.all([
                this.Action_get_informacion_header(this.id),
                this.Action_get_informacion_header_FS({id: this.id, token: this.token}),
                this.Action_get_history_header_FS({id: this.id, token: this.token})
            ])
        },
        editarDuracion(){
            this.$refs.modalEditarDuracion.toggle()
        },
        historialDuracion(){
            this.$refs.modalHistorialCambioDuracion.toggle()
        },
        async createNumber(){
            let valid = await this.$refs.validatorNumber.validate();
            if (valid) {
                let obj = {
                    numero: this.identificacion,
                    id: this.idProyecto
                }
                await this.Action_post_numero_identificacion(obj)
                // close popover
                this.visibleCrearNum = !this.visibleCrearNum
                await this.Action_get_informacion_header(this.$route.params.id)
            }
        },
		tabsServiciosVer(){
			return [
				{
                    titulo:'Información',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/informacion`, "_self")},
					icono: 'icon-information-outline',
                },
                {
                    titulo:'Timeline',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/informe`, "_self")},
					icono: 'icon-text-box',
                },
                {
                    titulo:'Productos',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/productos/lista`, "_self")},
					icono: 'icon-cube',
                },
                {
                    titulo:'Actas',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/actas`, "_self")},
					icono: 'icon-confirmacion',
                },
                {
                    titulo:'Cobros',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/cobros`, "_self")},
					icono: 'icon-cash-multiple',
                },
                {
                    titulo:'Participantes',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/funcionarios`, "_self")},
					icono: 'icon-account',
                },
                {
                    titulo:'Cronograma',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/cronograma`, "_self")},
					icono: 'icon-calendar-month-outline',
                },
                {
                    titulo:'Presupuesto',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/presupuesto/gestion`, "_self")},
					icono: 'icon-clipboard-list',
                },
                {
                    titulo:'Indicadores',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/indicadores`, "_self")},
					icono: 'icon-progress-check',
                },
                {
                    titulo:'Protocolos',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/avance`, "_self")},
					icono: 'icon-format-list-checks',
                },
                {
                    titulo:'Pendientes',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/pendientes`, "_self")},
					icono: 'icon-account-clock',
                },
                {
                    titulo:'Consultas',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/consultas`, "_self")},
					icono: 'icon-forum',
                },
                {
                    titulo:'Carpetas',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/carpetas`, "_self")},
					icono: 'icon-folder-multiple',
                },
                {
                    titulo:'Notas digitales',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/notas-digitales`, "_self")},
					icono: 'icon-cellphone-text',
                },
                {
                    titulo:'Comentarios internos',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/comentarios`, "_self")},
					icono: 'icon-message-reply-text',
                },
                {
                    titulo:'Correo',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/correos`, "_self")},
					icono: 'icon-mail-alt',
                },
                {
                    titulo:'Comentarios clientes',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/comentarios-cliente`, "_self")},
					icono: 'icon-message-reply-text',
                },
                {
                    titulo:'Dashboard',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/dashboard`, "_self")},
					icono: 'icon-chart-line',
                },
                {
                    titulo:'Configuraciones',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/parametros-porcentajes`, "_self")},
					icono: 'icon-cog',
                },
                {
                    titulo:'Componentes',
                    callback: () => {window.open(`${this.FS}/proyectos/ver/${this.idProyecto}/componentes/materiales`, "_self")},
					icono: 'icon-cart-arrow-down',
                },
                {
                    titulo:'Equipos',
                    ruta:"obras.vigentes.ver.equipos",
					icono: 'icon-robot-industrial'
                },
                {
                    titulo:'Cotizaciones',
                    ruta:"obras.vigentes.ver.cotizaciones",
					icono: 'icon-menu-clipboard-text'
                },
                {
                    titulo:'Ordenes compra',
                    ruta:"obras.vigentes.ver.orden.compra",
					icono: 'icon-menu-shopping'
                },
                {
                    titulo:'Servicio',
                    ruta:"obras.vigentes.ver.servicios",
					icono: 'icon-menu-hammer-screwdriver'
                },
			]
		}
    }

}
</script>

<style lang="scss" scoped>
.inputDisable{
    height: 40px;
    width: 30%;
    background-color: beige;

}
.bg-semi-transparent{
    background: #525252dd;
}
.estilo-img{
    left:10px;
    bottom:-45px;
    box-shadow: 0 1px 4px 1px var(--color-shadow);
}
</style>