<template>
    <div v-if="informacionFS.proyecto">
        <div v-if="informacionFS.proyecto.timeline_cliente === 1 && informacionFS.calendario.nombre" class="row mx-0 my-3 border bg-whitesmoke br-4 pt-1 pb-4 px-2">
            <div class="col-auto text-muted d-middle">
                <i class="icon-calendar-check f-25 text-warning" />
                <span class="f-14 f-600 mx-2">
                    {{ informacionFS.calendario.nombre }}
                </span>
                <span class="f-14">
                    {{ informacionFS.calendario.inicio || informacionFS.calendario.historial.fecha_inicio | helper-fecha('YYYY-MM-DD') }}
                    {{ informacionFS.calendario.fin || informacionFS.calendario.historial.fecha_fin | helper-fecha('YYYY-MM-DD') }}
                </span>
                <span class="f-14 ml-2 f-600">
                    |  Progreso: {{ informacionFS.calendario.historial.porcentaje }}%
                </span>
            </div>
            <div v-if="informacionFS.calendario.grupos" class="col-10 d-middle position-relative mt-4">
                <!-- Esfera inicio -->
                <div class="px-0 position-absolute" style="width: 0%;">
                    <div class="bg-dark pb-1 position-relative">
                        <el-tooltip effect="light" placement="top" class="cr-pointer">
                            <p slot="content" class="f-12 text-center">
                                {{ informacionFS.calendario.inicio | helper-fecha('YYYY-MM-DD') }}
                            </p>
                            <div class="rounded-circle position-absolute bg-white border-gray timeline-circle" style="z-index:3;" />
                        </el-tooltip>
                    </div>
                </div>
                <!-- Línea timeline -->
                <div
                v-for="(grupo, index) in informacionFS.calendario.grupos"
                :key="index" class="px-0 position-absolute"
                :style="calcularPosicion(grupo.posicion, index)"
                >
                    <div class="bg-dark pb-1 position-relative">
                        <el-tooltip effect="light" placement="bottom" class="cr-pointer">
                            <div slot="content" class="f-12 text-center" style="max-width:200px">
                                <p class="f-600 mb-1">
                                    {{ grupo.nombre }}
                                </p>
                                <p>
                                    {{ grupo.fecha_inicio | helper-fecha('YYYY-MM-DD') }} - {{ grupo.fecha_fin | helper-fecha('YYYY-MM-DD') }}
                                </p>
                            </div>
                            <!-- Esfera grupo -->
                            <div
                            :class="validarEstadoActividad(grupo)"
                            class="rounded-circle position-absolute bg-white timeline-circle p-1 d-middle justify-content-center"
                            style="z-index:10"
                            >
                                <span class="f-12">
                                    {{ index + 1 }}
                                </span>
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <!-- Bandera día hoy -->
                <div class="px-0 position-absolute" :style="`width:117%;`">
                    <div class="bg-dark pb-1 position-relative ">
                        <el-tooltip effect="light" placement="top" class="cr-pointer">
                            <p slot="content" class="f-12 text-center">
                                Hoy
                            </p>
                            <i class="icon-flag-triangle f-20 position-absolute"
                                style="right: -11px;z-index:11; bottom: -5px;"
                            />
                        </el-tooltip>
                    </div>
                </div>
                <!-- Esfera final -->
                <div class="px-0 position-absolute timeline-cirle-start-end" style="width: calc(100%);">
                    <div class="bg-dark pb-1 position-relative">
                        <el-tooltip effect="light" placement="top">
                            <p slot="content" class="f-12 text-center">
                                {{ informacionFS.calendario.fin | helper-fecha('YYYY-MM-DD') }}
                            </p>
                            <div class="rounded-circle position-absolute bg-white border-gray timeline-circle cr-pointer" style="z-index:3;" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>

        <!-- FECHAS ACTUALIZACIÓN Y PROGRESO -->
        <div v-if="informacionFS.proyecto.timeline_cliente === 1 && informacionFS.calendario.nombre" class="row mx-0 my-3 border bg-whitesmoke br-4 pt-1 pb-4 px-2">
            <div v-if="informacionFS.calendario.grupos" class="col-10 d-middle position-relative mt-4">
                <!-- Esfera inicio -->
                <div class="px-0 position-absolute" style="width: 0%;">
                    <div class="bg-dark pb-1 position-relative">
                        <el-tooltip effect="light" placement="top" class="cr-pointer">
                            <p slot="content" class="f-12 text-center">
                                {{ informacionFS.calendario.inicio | helper-fecha('YYYY-MM-DD') }}
                            </p>
                            <div class="rounded-circle position-absolute bg-white border-gray timeline-circle" style="z-index:3;" />
                        </el-tooltip>
                    </div>
                </div>
                <!-- Línea timeline -->
                <div
                v-for="(grupo, index) in informacionFS.calendario.grupos"
                :key="index" class="px-0 position-absolute"
                :style="calcularPosicion(grupo.posicionActualizacion, index, true)"
                >
                    <div class="bg-dark pb-1 position-relative">
                        <el-tooltip effect="light" placement="bottom" class="cr-pointer">
                            <div slot="content" class="f-12 text-center" style="max-width:200px">
                                <p class="f-600 mb-1">
                                    {{ grupo.nombre }}
                                </p>
                                <p>
                                    {{ grupo.actualizacion | helper-fecha('YYYY-MM-DD') }}
                                </p>
                            </div>
                            <!-- Esfera grupo -->
                            <el-tooltip effect="light" placement="top">
                                <div slot="content" style="max-width:250px">
                                    <span>
                                        {{ grupo.porcentaje }}%
                                    </span>
                                </div>
                                <div
                                :class="validarEstadoActividad(grupo)"
                                class="rounded-circle position-absolute bg-white timeline-circle p-1 d-middle justify-content-center"
                                style="z-index:10"
                                >
                                    <span class="f-12">
                                        {{ index + 1 }}
                                    </span>
                                </div>
                            </el-tooltip>
                        </el-tooltip>
                    </div>
                </div>
                <!-- Bandera día hoy -->
                <div class="px-0 position-absolute" :style="`width:117%;`">
                    <div class="bg-dark pb-1 position-relative ">
                        <el-tooltip effect="light" placement="top" class="cr-pointer">
                            <p slot="content" class="f-12 text-center">
                                Hoy
                            </p>
                            <i class="icon-flag-triangle f-20 position-absolute"
                                style="right: -11px;z-index:11; bottom: -5px;"
                            />
                        </el-tooltip>
                    </div>
                </div>
                <!-- Esfera final -->
                <div class="px-0 position-absolute timeline-cirle-start-end" style="width: inherit;">
                    <div class="bg-dark pb-1 position-relative">
                        <el-tooltip effect="light" placement="top" class="cr-pointer">
                            <p slot="content" class="f-12 text-center">
                                {{ informacionFS.calendario.fin | helper-fecha('YYYY-MM-DD') }}
                            </p>
                            <div class="rounded-circle position-absolute bg-white border-gray timeline-circle" style="z-index:3;" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            informacionFS: 'obras/informacion/informacionFS',
        })
    },
    methods: {
        calcularPosicion(posicion, indice, esElSegundo = false){
            const campo = esElSegundo ? 'posicionActualizacion' : 'posicion'
            const posicionSeRepite = this.informacionFS.calendario.grupos.filter(x => x[campo] === posicion).length > 1
            const operacion = posicion >= 90 ? this.restarPosicion: this.sumarPosicion
            const fueraDelLimite = posicion > 100
            if(fueraDelLimite && esElSegundo){
                return `width: ${this.posicionFueraLimite + 0.5}`
            }else{
                return `width: ${posicionSeRepite ? operacion(posicion, indice) : posicion}%`
            }
        },
        validarEstadoActividad(actividad){
            const PORCENTAJE_LIMITE = 100
            if(actividad.porcentaje < PORCENTAJE_LIMITE & actividad.posicionActualizacion > PORCENTAJE_LIMITE){
                return 'border-red'
            }else if (actividad.porcentaje < PORCENTAJE_LIMITE){
                return 'border-blue-light'
            }else{
                return 'border-green'
            }
        },
        sumarPosicion: (posicion, indice) => posicion + (indice)  + 0.5,
        restarPosicion: (posicion, indice) => (posicion - (indice)) - 0.5
    }
}
</script>

<style lang="scss" scoped>
.border-gray{
    border: 2px solid #6c757d

}
.border-blue-light{
    border: 2px solid #007bff
}
.border-green{
    border: 2px solid #00B41E
}
.border-red{
    border: 2px solid #EF2A2A
}

.timeline-circle{
    width:22px;
    height:22px;
    right: -13px;
    bottom: -9px;
}
</style>