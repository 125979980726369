<template>
    <modal-lateral ref="modalHistorialCambioDuracion" titulo="Historial cambio de duración">
        <div v-for="(data, index) in historyFS" :key="index" class="m-3 px-3 py-1 bg-whitesmoke br-10">
            <div class="d-flex my-2">
                <div class="border br-20 bg-white d-middle" style="width:150px;">
                    <img :src="data.usuario.foto | helper-storage"
                        class="rounded-circle obj-cover" width="30" height="30" />
                    <p class="mx-1 f-14 tres-puntos">{{ data.usuario.nombre }}</p>
                </div>
            </div>
            <div class="d-flex my-2 align-items-center">
                <i class="icon-calendar-today f-20 text-muted mr-2" />
                <span class="f-14">{{ data.fecha_inicio | helper-fecha('YYYY/MM/DD') }} - {{ data.fecha_fin | helper-fecha('YYYY/MM/DD') }}</span>
            </div>
            <div class="d-flex my-2 f-14  overflow-hidden">
                <p>{{ data.justificacion }}</p>
            </div>
            <div class="d-flex my-2 f-12 text-muted">
                <span>{{ data.created_at | helper-fecha('YYYY/MM/DD') }}</span>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            historyFS: 'obras/informacion/historyFS',
        })
    },
    methods: {
        toggle(){
            this.$refs.modalHistorialCambioDuracion.toggle()
        }
    }
}
</script>